import { Injectable } from '@angular/core';
import swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor() { }

  confirm(title: string, text: string) {
    return swal.fire({
      title,
      text,
      icon: 'warning',
      showClass: {
        popup: 'animated bounceIn'
      },
      showCancelButton: true,
      confirmButtonColor: '#2F8BE6',
      cancelButtonColor: '#F55252',
      confirmButtonText: 'Delete',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      buttonsStyling: false,
    });
  }

  // tslint:disable-next-line:typedef
  verify(title: string, text: string) {
    return swal.fire({
      title,
      text,
      icon: 'warning',
      showClass: {
        popup: 'animated bounceIn'
      },
      showCancelButton: true,
      confirmButtonColor: '#2F8BE6',
      cancelButtonColor: '#F55252',
      confirmButtonText: 'Verify',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      buttonsStyling: false,
    });
  }
  
  revoke(title: string, text: string) {
    return swal.fire({
      title,
      text,
      icon: 'warning',
      showClass: {
        popup: 'animated bounceIn'
      },
      showCancelButton: true,
      confirmButtonColor: '#2F8BE6',
      cancelButtonColor: '#F55252',
      confirmButtonText: 'Revoke Access',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      buttonsStyling: false,
    });
  }

}
