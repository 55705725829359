import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import {catchError, switchMap} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

import {Router} from '@angular/router';
import {SessionStorageService} from './session-storage.service';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthServicesInterceptor implements HttpInterceptor {
  constructor(
    private localStorage: SessionStorageService,
    private apiService: ApiService,
    private cookieService: CookieService,
    public router: Router
  ) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    request = this.addAuthHeader(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && error.error.error === 'The token has been revoked') {
          sessionStorage.removeItem('userDetails');
          this.router.navigateByUrl('/auth/login');
        }

        // refresh token
        return throwError(error);
      })
    );
  }

  addAuthHeader(request: HttpRequest<any>) {
    const localUser = this.localStorage.getCurrentUser();
    if (localUser !== null) {
      request = request.clone({
        setHeaders: {
          'X-CSRF-TOKEN': this.cookieService.get('sm_csrf_key')
        }
      });
    }
    return request;
  }
}
