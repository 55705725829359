import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setLocalStorage(key, data) {
    try {
      sessionStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to local storage', e);
      return null;
    }
  }

  getLocalStorage(key) {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (e) {
      console.error('Error getting data from local storage', e);
      return null;
    }
  }

  getCurrentUser() {
    return this.getLocalStorage('userDetails');
  }


  logout() {
    sessionStorage.removeItem('userDetails');
  }

  changeUserDetails(key: string, value: string) {
    const user = this.getCurrentUser();
    user[key] = value;
    this.setLocalStorage('userDetails', user);
  }

}
