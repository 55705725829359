<!--Start topbar header-->
<header class="topbar-nav">
	<nav id="header-setting" class="navbar navbar-expand fixed-top">
		<div class="toggle-menu" (click)="toggleSidebar()"> <i class="zmdi zmdi-menu"></i>
		</div>
		
		<ul class="navbar-nav align-items-center right-nav-link ml-auto">
			
			<li ngbDropdown class="nav-item">
				<a class="nav-link dropdown-toggle-nocaret position-relative" href="javascript:;" id="dropdownBasic4" ngbDropdownToggle> 
					<span class="user-profile">
						<img [src]="userDetails.avatar" class="img-circle" alt="user avatar">
					</span>
				</a>
				<ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu-right">
					<li ngbDropdownItem class="user-details">
						<a href="javaScript:;">
							<div class="media">
								<div class="avatar">
									<img class="align-self-start mr-3" [src]="userDetails.avatar" alt="user avatar">
								</div>
								<div class="media-body">
									<h6 class="mt-2 user-title">{{userDetails.name.toUpperCase()}}</h6>
									<p class="user-subtitle">mccoy@example.com</p>
								</div>
							</div>
						</a>
					</li>
					<!-- <li class="dropdown-divider"></li>
					<li ngbDropdownItem><i class="zmdi zmdi-comments mr-3"></i>Inbox</li>
					<li class="dropdown-divider"></li>
					<li ngbDropdownItem><i class="zmdi zmdi-balance-wallet mr-3"></i>Account</li>
					<li class="dropdown-divider"></li>
					<li ngbDropdownItem><i class="zmdi zmdi-settings mr-3"></i>Setting</li>
					<li class="dropdown-divider"></li> -->
					<li ngbDropdownItem >
						<a href="#" (click)="logout()"><i class="zmdi zmdi-power mr-3"></i>Logout</a></li>
				</ul>
			</li>
		</ul>
	</nav>
</header>
<!--End topbar header-->