import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    key: environment.key,
  }),
  withCredentials: true,
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  private static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      return throwError(`An error occurred:${error.error.message}`);
    } else {
      return throwError(error);
    }
  }

  get(url: string, params: Object = {}): Observable<any> {
    let path;
    path = Object.keys(params)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
      })
      .join('&');
    return this.http.get<any>(
      `${environment.api_url}${url}` + (path ? '?' + path : ''),
      this.setHeaders()
    );
  }

  delete(url: string, params: Object = {}): Observable<any> {
    let path;
    path = Object.keys(params)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
      })
      .join('&');
    return this.http.delete<any>(
      `${environment.api_url}${url}` + (path ? '?' + path : ''),
      this.setHeaders()
    );
  }

  post(url: string, data, params: Object = {}): Observable<any> {
    let path;
    path = Object.keys(params)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
      })
      .join('&');
    return this.http
      .post<any>(
        `${environment.api_url}${url}` + (path ? '?' + path : ''),
        JSON.stringify(data),
        this.setHeaders()
      )
      .pipe(catchError(ApiService.handleError));
  }

  put(url: string, data, params: Object = {}): Observable<any> {
    let path;
    path = Object.keys(params)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
      })
      .join('&');
    return this.http.put<any>(
      `${environment.api_url}${url}` + (path ? '?' + path : ''),
      JSON.stringify(data),
      this.setHeaders()
    );
  }

  post_multipart(url: string, data: FormData, params: Object = {}) {
    let path;
    path = Object.keys(params)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
      })
      .join('&');
    return this.http.post<any>(
      `${environment.api_url}${url}` + (path ? '?' + path : ''),
      data,
      this.setHeaders_multipart()
    );
  }

  put_multipart(url: string, data: FormData, params: Object = {}) {
    let path;
    path = Object.keys(params)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
      })
      .join('&');
    return this.http.put<any>(
      `${environment.api_url}${url}` + (path ? '?' + path : ''),
      data,
      this.setHeaders_multipart()
    );
  }

  setHeaders() {
    if (!httpOptions.headers.has('Content-Type')) {
      httpOptions.headers = httpOptions.headers.append(
        'Content-Type',
        'application/json'
      );
    }
    return httpOptions;
  }

  setHeaders_multipart() {
    if (httpOptions.headers.get('Content-Type')) {
      httpOptions.headers = httpOptions.headers.delete('Content-Type');
    }
    return httpOptions;
  }

  setHeadersForDownload() {
    httpOptions.headers = httpOptions.headers.append('responseType', 'blob');
    return httpOptions;
  }

  download(url: string, type: string = 'pdf') {
    return this.http.get(`${environment.api_url}${url}`, {
      headers: {
        Accept: `application/${type}`,
        key: `${environment.key}`,
      },
      responseType: 'arraybuffer',
    });
  }

  downloadFile(url: string, type: string = 'pdf') {
    return this.http.get(`${environment.api_url}${url}`, {
      headers: {
        Accept: `application/${type}`,
        key: `${environment.key}`,
      },
      responseType: 'blob' as 'json',
    });
  }

  handleFileDownload(response, fileName) {
    let dataType = response.type;
    let binaryData = [];
    binaryData.push(response);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(
      new Blob(binaryData, { type: dataType })
    );
    if (fileName) downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
