import {Injectable} from '@angular/core';
import {SessionStorageService} from './session-storage.service';
import {User} from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: User;

  constructor(
    private sessionStorage: SessionStorageService
  ) {
  }

  isAuthenticated(): boolean {
    this.user = this.sessionStorage.getCurrentUser();
    return this.user != null;
    //return true;
  }


}
