import { Routes } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'user',
        loadChildren: () => import('../../user/user.module').then(m => m.UserModule)
    },
    {
        path: 'sevadar',
        loadChildren: () => import('../../sevadar/sevadar.module').then(m => m.SevadarModule)
    },
    {
        path: 'daily-seva',
        loadChildren: () => import('../../pages/dailyseva/dailyseva.module').then(m => m.DailysevaModule)
    },
    {
        path: 'social-platform',
        loadChildren: () => import('../../pages/social-platform/social-platform.module').then(m => m.SocialPlatformModule)
    },
    {
        path: 'seva-learn',
        loadChildren: () => import('../../pages/seva-learn/seva-learn.module').then(m => m.SevaLearnModule)
    },
    {
        path: 'twitter-trending',
        loadChildren: () => import('../../pages/twitter-trending/twitter-trending.module').then(m => m.TwitterTrendingModule)
    },
    {
        path: 'setting',
        loadChildren: () => import('../../pages/setting/setting.module').then(m => m.SettingModule)
    },
    {
        path: 'latest-update',
        loadChildren: () => import('../../pages/latest-update/latest-update.module').then(m => m.LatestUpdateModule)
    },
    {
        path: 'components',
        loadChildren: () => import('../../components/components.module').then(m => m.ComponentsModule)
    },
    {
        path: 'ui-elements',
        loadChildren: () => import('../../ui-elements/ui-elements.module').then(m => m.UIElementsModule)
    },
    {
        path: 'charts',
        loadChildren: () => import('../../charts/charts.module').then(m => m.ChartsNg2Module)

    },
    {
        path: 'widgets',
        loadChildren: () => import('../../widgets/widgets.module').then(m => m.WidgetsModule)

    },
    {
        path: 'form',
        loadChildren: () => import('../../form/form.module').then(m => m.FormModule)
    },
    {
        path: 'calendar',
        loadChildren: () => import('../../fullcalendar/fullcalendar.module').then(m => m.FullcalendarModule)

    },
    {
        path: 'table',
        loadChildren: () => import('../../tables/tables.module').then(m => m.TablesModule)

    },
    {
        path: 'datatable',
        loadChildren: () => import('../../datatable/datatable.module').then(m => m.DatatableModule)

    },
    {
        path: 'ui-icons',
        loadChildren: () => import('../../ui-icons/ui-icons.module').then(m => m.UiIconsModule)

    },
    {
        path: 'maps',
        loadChildren: () => import('../../maps/maps.module').then(m => m.MapsModule)

    },
    {
        path: 'pages',
        loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)

    }
];