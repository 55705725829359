import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  public userDetails = new BehaviorSubject<User>(null);
  currentUser = this.userDetails.asObservable();


  constructor() {
  }

  setItem(key, data): void {
    try {
      sessionStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      return null;
    }
  }

  getLocalStorage(key): any {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (e) {
      return null;
    }
  }

  getCurrentUser(): any {
    return this.getLocalStorage('userDetails');
  }


  logout(): void {
    sessionStorage.removeItem('userDetails');
  }

  changeUserDetails(key: string, value: string): void {
    const user = this.getCurrentUser();
    user[key] = value;
    this.setItem('userDetails', user);
  }

  changeCurrentUserDetail(user: User) {
    this.userDetails.next(user);
  }
}
